.proposal-row-UNHANDLED-CASE {
    background: blue;
    color: red;
}

.proposal-row-UNHANDLED-CASE::before {
    color: red;
    content: "Something's not right...You're not supposed to see this.";
}

.proposal-row-gray {
    background: #dddddd;
    color: #666666;
}

.proposal-row-red {
    background: #fff6f6;
    color: black;
}

.proposal-row-yellow {
    background: #fffaf3;
    color: black;
}

.proposal-row-green {
    background: #fcfff5;
    color: black;
}

.proposal-status-action {
    display: flex;
    justify-content: space-between;
}

.proposal-status-action .dropdown {
    margin-right: 0.33em;
}

td.attachments {
    max-width: 25em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
