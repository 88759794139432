.proposal-submit-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.project-editor-input-container {
    display: flex;
    flex-direction: column;
}

.edited-input input,
.edited-input.dropdown {
    background-color: #fffaf3 !important;
    color: #573a08 !important;
}
